import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import DmsService from 'Services/DMS/dms-api.service';
import BoxHeader from 'Components/Box/BoxHeader';
import ManageDateFormatModal from 'Components/modals/ManageDateFormatModal/ManageDateFormatModal';
import DataBasedChart from 'Components/Info/Charts/DataBasedChart/DataBasedChart';
import DigitalView from 'Components/Info/DigitalView/DigitalView';
import { makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Resizable } from 'react-resizable';
import LiveJobSignalrDigital from 'Components/SignalrSubscribes/LiveJobSignalrDigital.component';
import LiveJobSignalrEventLogger from 'Components/SignalrSubscribes/LiveJobSignalrEventLogger.component';
import { sortChannels, uniqueValues } from 'helpers';
import AlertToastr from 'utils/alert';
import DataTable from '../../Components/DataTable/DataTable.component';
import EventLogger from 'Components/EventLogger/EventLogger';
import JobProperties from 'Components/JobProperties/JobProperties';
import ManageAxesModal from 'Components/modals/ManageAxisModal/ManageAxesModal';
import LiveJobSignalrJobProperties from '../SignalrSubscribes/LiveJobSignalrJobProperties.component';
import { useAuth } from '../../Contexts/AuthProvider';
import LiveJobSignalrTimeBasedNew from '../SignalrSubscribes/LiveJobSignalrTimeBasedNew.component';
import TimeBasedChart from '../Charts/TimeBasedChart';
import { useAppSettings } from '../../Contexts/AppSettingsProvider';
import LiveJobSignalRDatatable from '../SignalrSubscribes/LiveJobSignalRDatatable';
import Button from '@material-ui/core/Button';
import { Cached } from '@material-ui/icons';
import useTranslation from '../../Hooks/useTranslation';
import DataTableCustomDateBoxFilter from '../filters/DataTableCustomDateBoxFilter';
import { useBoxes } from 'Contexts/BoxContext';
import moment from 'moment'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

const defaultChartHeight = 450;

const Box = props => {

    const {
        boxData = null,
        handleEdit,
        onNewWindowOpen,
        onRemove,
        index,
        currentJob,
        dashboard,
        onExportToCsv,
        tabId,
        dragHandleProps,
        syncCurrentTabs,
        groupBoundChannelColors,
        eventTypes,
        onFilterChange,
    } = props;

    const classes = useStyles();

    const {
        type,
        channels,
        yAxisSettings,
        xAxisSettings,
        timeType,
        timeFormat,
        chartType = '',
        mainXYChannel = null,
    } = boxData;


    const sortedChannels = useMemo(() => {
        if (!channels) return [];
        return sortChannels(currentJob, channels);
    }, [channels, currentJob]);

    const translation = useTranslation();
    const { appTheme: theme } = useAppSettings();

    const { token, currentCompany } = useAuth();
    const [dataLoading, setDataLoading] = useState(false);
    const [data, setData] = useState(null);
    const [currentTimestamp, setCurrentTimestamp] = useState(null);
    const [lastLoadedDmsId, setLastLoadedDmsId] = useState(0);
    const [chartHeight, setChartHeight] = useState(null);
    const [needResetDateFilters, setNeedResetDateFilters] = useState(false);
    // const { jobUpdateCounter } = useContext(JobContext);
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [reverseChart, setReverseChart] = useState(false);
    const [manageDateFormatModalIsOpen, setManageDateFormatModalIsOpen] = useState(false);
    const [manageAxesModalIsOpen, setManageAxesModalIsOpen] = useState(false);
    const [dateTimeFormat, setDateTimeFormat] = useState('');
    const [refreshCounter, setRefreshCounter] = useState(0);
    const { unitsRequest,updateChartDateRange } = useBoxes();
    const [exportImage, setExportImage] = useState(false);

    const handleExportToImage = () => {
      setExportImage(true);
      setTimeout(() => setExportImage(false), 1000); 
  };

    const signalrRequestBody = useMemo(() => {

        return ({
            channels: channels?.map(ch => ({ channelId: ch.id, unit: ch.unit })),
            ...boxData?.filters,
            jobId: currentJob?.id,
        });
    }, [boxData, channels, currentJob]);


    // const channelsIds = useMemo(() => channels ? channels.map(ch => ch.id) : null, [channels]);

    const timeDiff = useMemo(() => {

        if (timeType === 'elapsed') {
            if (data && data.length) {
                const firstPointsTimeArray = [];
                data.forEach(channelData => {
                    if (channelData && channelData.data && channelData.data[0] && channelData.data[0][0]) {
                        firstPointsTimeArray.push(channelData.data.reduce((min, ch) => ch[0] < min ? ch[0] : min, channelData.data[0][0]));
                    }
                });
                return Math.min(...firstPointsTimeArray);
            }
        }
        return null;
    }, [
        timeType,
        data,
    ]);

    const selectedUnits = useMemo(() => channels ? uniqueValues(channels.map(ch => ch.unit)) : null, [channels]);

    useEffect(() => {
        setChartHeight(defaultChartHeight);
    }, []);

    const chartResizeConfig = {
        onResize: (e, dir, reftoEl) => {
            setChartHeight(reftoEl.offsetHeight);
        },
        enable: {
            top: false,
            right: false,
            bottom: true,
            left: false,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false,
        },
        minHeight: 200,
    };

    const fetchData = useCallback(async (job, channels, isResetDateFilter = false) => {

        const sortLastChannelData = (data) => {
            data.sort((a, b) => {
                const aUnitId = currentJob.units.find(unit => unit.id === a.unitId).dmsId;
                const bUnitId = currentJob.units.find(unit => unit.id === b.unitId).dmsId;
                if (aUnitId > bUnitId)
                    return 1;
                if (aUnitId < bUnitId)
                    return -1;
                return a.dmsId > b.dmsId ? 1 : -1;
            });
        };

        try {
            let serverData;
            setDataLoading(true);
            if (type === 'digitalView') {
                if (currentJob) {
                    const channelsData = channels.map(ch => ({ channelId: ch.id, unit: ch.unit }));
                    try {
                        const serverResponse = await DmsService.getLastChannelsData(channelsData);
                        serverData = channels.map((ch) => {
                            ch.value = serverResponse.channelDataResponses.find(channel => channel.channel.id === ch.id).data[0].value;
                            return ch;
                        });
                        setDataLoading(false);
                        sortLastChannelData(serverData);
                    } catch (e) {
                        console.log(e);
                    }

                }
            }
            if (type === 'eventLogger') {
                let eventResponse = await DmsService.getEvents(job.id);
                // const ids = eventResponse.data.events.map(e => e.dmsId)
                const ids = eventResponse.data.events.map(e => e.senderDateTimeUtc);
                setLastLoadedDmsId(Math.max(...ids));
                serverData = eventResponse.data.events;
                    setDateTimeFormat(eventResponse.data.dateTimeFormat);
            }
            if (type === 'jobProperties') {
                let jobPropertiesResponse = await DmsService.getJobProperties(job.id);
                //TODO: jobProperties last id
                const ids = jobPropertiesResponse.data.jobProperties.map(e => e.dmsId);
                setLastLoadedDmsId(Math.max(...ids));

                serverData = jobPropertiesResponse.data.jobProperties.filter(item => !item.isDeleted);
                setDateTimeFormat(jobPropertiesResponse.data.dateTimeFormat);
            }
            if (type === 'dataTable' || chartType === 'timeBased') {
                if (type === 'dataTable') {

                    /** data table old **/
                    const channelsData = channels.map(ch => ({ channelId: ch.id, unit: ch.unit }));
                    const initBody = {
                        channels: channelsData,
                        ...boxData.filters,
                        jobId: currentJob?.id,
                    };
                    const serverResponse = await DmsService.getChannelsDataInitWithFilters(initBody);
                    const startTime = new Date(serverResponse.lastProcessedTimestamp);
                    startTime.setMinutes(startTime.getMinutes() - startTime.getTimezoneOffset());
                    startTime.setSeconds(0);
                    startTime.setMilliseconds(0);
                    if (isResetDateFilter) {
                        setNeedResetDateFilters(true);
                        setDateFrom(startTime.toISOString().replace('Z', ''));
                    } else {
                        setDateFrom(prev => prev ? prev : startTime.toISOString().replace('Z', ''));
                        setNeedResetDateFilters(false);
                    }

                    serverData = sortChannels(currentJob, serverResponse.channelDataResponses);
                    setCurrentTimestamp(serverResponse.lastProcessedTimestamp);
                    setDateTimeFormat(serverResponse.dateTimeFormat);

                    setDataLoading(false);
                    setData([...serverData]);
                } else {
                    const channelsData = channels.map(ch => ({ channelId: ch.id, unit: ch.unit }));
                    const serverResponse = await DmsService.getChannelsDataInit(channelsData);
                    const serverDataMap = {};
                    serverData = sortChannels(currentJob, serverResponse.channelDataResponses);
                    serverData.forEach(channelData => {
                        const { channel, data } = channelData;
                        serverDataMap[channel.id] = data;
                    });

                    setData(serverDataMap);
                    setCurrentTimestamp(serverResponse.lastProcessedTimestamp);
                    setDateTimeFormat(serverResponse.dateTimeFormat);
                    setDataLoading(false);
                }
                return;
            } else if (chartType === 'dataBased') {
                const databaseChannelsData = {
                    'channelX': {
                        'channelId': mainXYChannel,
                        'unit': 'ms',
                    },
                    'channelsY': channels.map(ch => ({ channelId: ch.id, unit: ch.unit })),
                };
                serverData = await DmsService.getChannelsDataByCustomAxis(databaseChannelsData);
                serverData = sortChannels(currentJob, serverData);

                setCurrentTimestamp(serverData.lastProcessedTimestamp);
                setDateTimeFormat(serverData.dateTimeFormat);
            }

            setDataLoading(false);
            setData([...serverData]);
        } catch (error) {
            if (error && error.response && error.response.data && error.response.data.Message)
                AlertToastr.showErrorAlert(error.response.data.Message);
            setDataLoading(false);
        }
    }, [
        currentJob,
        chartType,
        mainXYChannel,
        type,
        boxData,
    ]);

    useEffect(() => {

        if (
            currentJob
            // && jobUpdateCounter
        ) {
            fetchData(currentJob, channels).then();
        }

    }, [
        currentJob,
        channels,
        fetchData,
    ]);

    const handleManageChannels = () => {
        handleEdit(index);
    };

    const handleManageAxesSettings = () => {
        setManageAxesModalIsOpen(true);
    };

    const handleManageDateFormat = () => {
        setManageDateFormatModalIsOpen(true);
    };

    const handleExportToCsv = () => {
        onExportToCsv(index);
    };

    const saveDateFormat = (timeType, timeFormat) => {
        boxData.timeType = timeType;
        boxData.timeFormat = timeFormat;
        syncCurrentTabs();
    };

    const handleLiveData = useCallback(incomingData => {

        if (type === 'digitalView') {
            if (incomingData && incomingData.length) {

                setData(data => {
                    const newData = data ? [...data] : [];
                    incomingData.forEach(channelData => {
                        const { channelId, value } = channelData;
                        const channelIndexFromExistingData = newData.findIndex(item => item.id === channelId);

                        if (channelIndexFromExistingData !== -1) {
                            newData[channelIndexFromExistingData].value = value || 0;
                        }
                    });
                    return newData;
                });
            }
        }

        if (type === 'eventLogger') {

            if (incomingData && incomingData.length) {
                setData(data => {
                    const newData = data ? [...data] : [];
                    incomingData.forEach(eventData => {
                        const eventIndex = newData.findIndex(item => item.dmsId === eventData.dmsId);

                        if (eventIndex === -1) {
                            newData.push(eventData);
                        } else {
                            newData[eventIndex] = eventData;
                        }
                    });
                    return newData;
                });
            }
        }

        if (type === 'jobProperties') {

            if (incomingData && incomingData.length) {
                setData(data => {

                    const newData = data ? [...data] : [];

                    incomingData.forEach(eventData => {
                        const eventIndex = newData.findIndex(item => item.dmsId === eventData.dmsId);

                        if (eventIndex === -1) {
                            newData.push(eventData);
                        } else {
                            newData[eventIndex] = eventData;
                        }
                    });
                    return newData;
                });
            }
        }

        if (type === 'chartView' && chartType === 'timeBased') {
            if (incomingData && incomingData.length) {
                setData(prevData => {
                    const newData = prevData ? { ...prevData } : {};
                    incomingData.forEach((channelData) => {
                        const { channel, data } = channelData;
                        const newChannelData = newData[channel.id] || [];
                        newData[channel.id] = [...newChannelData, ...data];
                    });
                    return newData;
                });
            }
        }

        if (type === 'dataTable') {
            setData(prevData => {
                const newData = prevData ? [...prevData] : [];
                incomingData.forEach((channelData) => {
                    const { channel, data } = channelData;
                    const chIdx = newData.findIndex(chData => chData.channel.id === channel.id);
                    if (chIdx !== -1) {
                        newData[chIdx].data = [...newData[chIdx].data, ...data];
                    }
                });
                return newData;
            });
        }

    }, [setData, chartType, type]);

    const handleDeleteLiveData = useCallback(incomingData => {
        if (type === 'eventLogger') {
            if (incomingData && incomingData.length) {
                setData(data => {
                    const newData = data ? [...data] : [];
                    incomingData.forEach(eventData => {
                        const eventIndex = newData.findIndex(item => item.dmsId === eventData.dmsId);

                        if (eventIndex > -1) {
                            newData.splice(eventIndex, 1);
                        }
                    });
                    return newData;
                });
            }
        }

        if (type === 'jobProperties') {
            //TODO: jobProperties live data delete handler
            if (incomingData && incomingData.length) {
                setData(data => {
                    const newData = data ? [...data] : [];
                    incomingData.forEach(eventData => {
                        const eventIndex = newData.findIndex(item => item.dmsId === eventData.dmsId);

                        if (eventIndex > -1) {
                            newData.splice(eventIndex, 1);
                        }
                    });
                    return newData;
                });
            }
        }
    }, [setData, type]);

    const handleSaveChartSettings = (yAxisSettings, xAxisSettings, timeType, timeFormat) => {
        boxData.yAxisSettings = yAxisSettings;
        boxData.xAxisSettings = xAxisSettings;
        boxData.timeType = timeType;
        boxData.timeFormat = timeFormat;
        syncCurrentTabs();
    };

    const handleChangeFilters = (filters) => {
        if (onFilterChange) {
            onFilterChange({ ...boxData, filters: { ...boxData.filters, ...filters } });
        }
    };

    const handleRefresh = () => {
        if (currentJob) {
            fetchData(currentJob, channels, true).then(() => {
                setRefreshCounter(c => c + 1);
            });
        }
    };

    const getLocalTimeFromUtcForJobs  = (utcDate, timezone) => {
        const localMoment = moment.tz(utcDate, timezone);
        const utcMoment = localMoment.utc();
        return utcMoment.tz(timezone).toDate();
      };

    const handleLiveIncomingDataStop = useCallback((lastTimestamp) => {
        const timezone = moment.tz.guess();
        const endTime = new Date(lastTimestamp);
        endTime.setMinutes(endTime.getMinutes() - endTime.getTimezoneOffset());
        // endTime.setSeconds(0);
        endTime.setMilliseconds(0);
        if (needResetDateFilters || !dateTo) {
            setDateTo(endTime.toISOString().replace('Z', ''));
            updateChartDateRange(getLocalTimeFromUtcForJobs(currentJob.startTime, timezone), getLocalTimeFromUtcForJobs(endTime.toISOString().replace('Z', ''), timezone))
        }

    }, [needResetDateFilters, dateTo, updateChartDateRange, currentJob.startTime]);

    let boxHeader = null;
    let boxContent = null;

    switch (type) {
        case 'chartView':
            boxHeader = (
                <BoxHeader
                    box={boxData}
                    type={type}
                    handleEdit={handleManageChannels}
                    onRemove={onRemove}
                    onNewWindowOpen={() => onNewWindowOpen(index, dashboard.id)}
                    setReverseChart={setReverseChart}
                    timeType={timeType}
                    timeFormat={timeFormat}
                    chartType={chartType}
                    reverse={reverseChart}
                    onManageAxesSettings={handleManageAxesSettings}
                    onManageDateFormat={handleManageDateFormat}
                    tabId={tabId}
                    itemId={boxData.id}
                    dragHandleProps={dragHandleProps}
                    onExportToCsv={handleExportToCsv}
                    onExportToImage={handleExportToImage} 
                />
            );

            boxContent = chartType === 'timeBased'
                ? (
                    <Resizable {...chartResizeConfig}>
                        <TimeBasedChart
                            id={boxData.id}
                            lt={currentTimestamp}
                            job={currentJob}
                            channels={sortedChannels}
                            channelsData={data}
                            chartHeight={chartHeight}
                            timeType={timeType}
                            timeFormat={timeFormat}
                            yAxisSettings={yAxisSettings}
                            xAxisSettings={xAxisSettings}
                            boundChannelList={groupBoundChannelColors}
                            dateTimeFormat={dateTimeFormat}
                            onExportImage={exportImage} 
                        />
                    </Resizable>
                ) :
                <Resizable {...chartResizeConfig}>
                    <DataBasedChart
                        job={currentJob}
                        reverse={reverseChart}
                        channelsData={data}
                        selectedUnits={selectedUnits}
                        chartHeight={chartHeight}
                        theme={theme}
                        mainXYChannel={mainXYChannel}
                        channels={channels}
                        currentTimestamp={currentTimestamp}
                        yAxisSettings={yAxisSettings}
                        xAxisSettings={xAxisSettings}
                    />
                </Resizable>;
            break;
        case 'digitalView':
            boxHeader = (
                <BoxHeader
                    box={boxData}
                    type={type}
                    handleEdit={handleManageChannels}
                    onRemove={onRemove}
                    onNewWindowOpen={() => onNewWindowOpen(index, dashboard.id)}
                    tabId={tabId}
                    itemId={boxData.id}
                    dragHandleProps={dragHandleProps}
                />
            );

            boxContent = (
                <DigitalView
                    channelsData={data}
                    boundChannelList={groupBoundChannelColors}
                />
            );
            break;
        case 'dataTable':
            boxHeader = (
                <BoxHeader
                    box={boxData}
                    type={type}
                    handleEdit={handleManageChannels}
                    onRemove={onRemove}
                    onNewWindowOpen={() => onNewWindowOpen(index, dashboard.id)}
                    onManageDateFormat={handleManageDateFormat}
                    tabId={tabId}
                    itemId={boxData.id}
                    dateFrom={dateFrom}
                    dateTo={dateTo}
                    setDateFrom={setDateFrom}
                    setDateTo={setDateTo}
                    timeType={timeType}
                    onExportToCsv={handleExportToCsv}
                    timeFormat={timeFormat}
                    dragHandleProps={dragHandleProps}
                />
            );

            boxContent = (
                <Fragment>
                    <div className="pageBtnSection pt0">
                        <div style={{ display: 'flex' }}>
                            {
                                <DataTableCustomDateBoxFilter
                                    initData={boxData.filters}
                                    onChange={handleChangeFilters}
                                    dateFrom={dateFrom}
                                    dateTo={dateTo}
                                    onDateFromChange={date => setDateFrom(date)}
                                    onDateToChange={date => setDateTo(date)}
                                />
                                // isFirefox ? (
                                //     <DataTableCustomDateBoxFilter
                                //         initData={boxData.filters}
                                //         onChange={handleChangeFilters}
                                //         dateFrom={dateFrom}
                                //         dateTo={dateTo}
                                //         onDateFromChange={date => setDateFrom(date)}
                                //         onDateToChange={date => setDateTo(date)}
                                //     />
                                // ) : (
                                //     <DataTableBoxFilter
                                //         initData={boxData.filters}
                                //         onChange={handleChangeFilters}
                                //         dateFrom={dateFrom}
                                //         dateTo={dateTo}
                                //         onDateFromChange={e => setDateFrom(e.target.value)}
                                //         onDateToChange={e => setDateTo(e.target.value)}
                                //     />
                                // )
                            }

                            <Button
                                className='refreshBtn'
                                onClick={handleRefresh}
                                disabled={false}
                                startIcon={<Cached />}
                            >
                                <span className='hideTitleRefreshBtn'>{translation.refreshButtonLabel}</span>
                            </Button>
                        </div>
                    </div>
                    <DataTable
                        channelsData={data}
                        dateFrom={dateFrom}
                        dateTo={dateTo}
                        job={currentJob}
                        timeType={timeType}
                        timeFormat={timeFormat}
                        timeDiff={timeDiff}
                        dateTimeFormat={dateTimeFormat}
                    />
                </Fragment>
            );
            break;
        case 'eventLogger':
            boxHeader = (
                <BoxHeader
                    box={boxData}
                    type={type}
                    onRemove={onRemove}
                    onNewWindowOpen={() => onNewWindowOpen(index, dashboard.id)}
                    onManageDateFormat={handleManageDateFormat}
                    tabId={tabId}
                    itemId={boxData.id}
                    timeType={timeType}
                    onExportToCsv={onExportToCsv}
                    timeFormat={timeFormat}
                    dragHandleProps={dragHandleProps}
                />
            );

            boxContent = (
                <EventLogger
                    data={data}
                    job={currentJob}
                    timeType={timeType}
                    timeFormat={timeFormat}
                    timeDiff={timeDiff}
                    dateTimeFormat={dateTimeFormat}
                    eventTypes={eventTypes}
                    jobsAdditionalHeader={true}
                    unitsRequest={unitsRequest}
                />
            );
            break;
        case 'jobProperties':

            boxHeader = (
                <BoxHeader
                    box={boxData}
                    type={type}
                    onRemove={onRemove}
                    onNewWindowOpen={() => onNewWindowOpen(index, dashboard.id)}
                    onManageDateFormat={handleManageDateFormat}
                    tabId={tabId}
                    itemId={boxData.id}
                    timeType={timeType}
                    onExportToCsv={onExportToCsv}
                    timeFormat={timeFormat}
                    dragHandleProps={dragHandleProps}
                />
            );

            boxContent = (
                <JobProperties
                    data={data}
                    job={currentJob}
                    timeType={timeType}
                    timeFormat={timeFormat}
                    timeDiff={timeDiff}
                    dateTimeFormat={dateTimeFormat}
                    eventTypes={eventTypes}
                />
            );
            break;
        default:
            boxHeader = null;
            boxContent = null;
    }

    const renderSignalR = () => {

        if (type === 'eventLogger') {
            return (
                <LiveJobSignalrEventLogger
                    jobId={currentJob.id}
                    lastLoadedDmsId={lastLoadedDmsId}
                    onIncomingData={handleLiveData}
                    onIncomingDeleteData={handleDeleteLiveData}
                />
            );
        }

        if (type === 'jobProperties') {
            return (
                <LiveJobSignalrJobProperties
                    jobId={currentJob.id}
                    lastLoadedDmsId={lastLoadedDmsId}
                    onIncomingData={handleLiveData}
                    onIncomingDeleteData={handleDeleteLiveData}
                />
            );
        }

        if (type === 'digitalView' && currentJob && !currentJob.stopTime) {
            return (
                <LiveJobSignalrDigital
                    channels={channels}
                    onIncomingData={handleLiveData}
                />
            );
        }

        if (chartType === 'timeBased')
            return (
                <LiveJobSignalrTimeBasedNew
                    channels={channels}
                    onIncomingData={handleLiveData}
                    lastProcessedTimestamp={currentTimestamp}
                />
            );

        if (type === 'dataTable') {
            if (data === undefined) return null;
            if (dataLoading) return null;
            return (
                <LiveJobSignalRDatatable
                    refreshCounter={refreshCounter}
                    token={token}
                    companyId={currentCompany?.id}
                    requestBody={signalrRequestBody}
                    onIncomingData={handleLiveData}
                    lastProcessedTimestamp={currentTimestamp}
                    onIncomingDataStop={handleLiveIncomingDataStop}
                />
            );
        }

        return null;
    };

    return (
        <Fragment>
            {boxHeader}
            <Fade
                in={dataLoading}
                style={{ transitionDelay: dataLoading ? '800ms' : '0ms' }}
                unmountOnExit
            >
                <div style={{
                    backgroundColor: theme === 'Dark' ? '#000' : '#fff',
                    zIndex: 100,
                    position: 'absolute',
                    height: '100%',
                    width: '100%',
                    opacity: 0.7,
                }}>
                    <div style={{
                        display: 'flex',
                        height: '100%',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <div className={classes.root}>
                            <CircularProgress />
                        </div>
                    </div>
                </div>
            </Fade>

            <div className="pageCard">
                {boxContent}
            </div>

            <ManageAxesModal
                isOpen={manageAxesModalIsOpen}
                setOpen={setManageAxesModalIsOpen}
                editedModel={boxData}
                reverseChart={false}
                onSave={handleSaveChartSettings}
                timeType={timeType}
                timeFormat={timeFormat}
            />

            <ManageDateFormatModal
                isOpen={manageDateFormatModalIsOpen}
                setOpen={setManageDateFormatModalIsOpen}
                onSave={saveDateFormat}
                dateFrom={dateFrom}
                dateTo={dateTo}
                setDateFrom={setDateFrom}
                setDateTo={setDateTo}
                timeType={timeType}
                timeFormat={timeFormat}
                type={type}
                chartType={chartType}
            />
            {(currentJob) && (renderSignalR())}
        </Fragment>
    );
};

const mapStateToProps = ({ dashboard }) => ({
    currentJob: dashboard.currentJob,
});

export default connect(mapStateToProps, null)(Box);
