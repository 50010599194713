export default {
    continueButtonLabel: 'Next',
    cancelButtonLabel: 'Cancel',
    closeButtonLabel: 'Close',
    refreshButtonLabel: 'Refresh',
    saveButtonLabel: 'Save',
    editButtonLabel: 'Edit',
    nextButtonLabel: 'Next',
    nextButtonTooltipLabel: 'There are no available templates for selected job',
    loadButtonLabel: 'Next',
    createButtonLabel: 'Create',
    openInNewWindowButtonLabel: 'Open In New Window',
    exportButtonLabel: 'Export',
    filterButtonLabel: 'Filter',
    resetButtonLabel: 'Reset',
    applyButtonLabel: 'Apply',
    deleteButtonLabel: 'Remove',
    cloneButtonLabel: 'Clone',
    completeButtonLabel: 'Complete',
    changeButtonLabel: 'Change',
    sendButtonLabel: 'Send',
    refreshButtonLabel: 'Refresh',
    signUpButtonLabel: 'Sign Up',
    signInButtonLabel: 'Sign In',
    openButtonLabel: 'Open',
    deleteAccountButtonLabel: 'Delete Account',
    addButtonLabel: 'Add',
    saveTemplateButtonLabel: 'Save Template',
    exportJobToCsvLabel: 'Export Job data to CSV',
    exportUnitToCsvLabel: 'Export Unit data to CSV',
    exportLocationToCsvLabel: 'Export Location data to CSV',
    changeJobLabel: 'Change Job',
    changeUnitLabel: 'Change Unit',

    headerUnitsLabel: 'Units',
    headerLocationsLabel: 'Locations',
    headerDmsCloudLabel: 'Jobs',
    headerSettings: 'Settings',
    headerMmsLabel: 'CAMMS',
    headerCompanyLabel: 'Company',
    headerChangeCompanyLabel: 'Change Company',
    headerMenuChangePasswordLabel: 'Change Password',
    headerMenuSignOutLabel: 'Sign Out',
    headerMenuSignOutMessageLabel: 'Are you sure you want to sign out?',
    headerMenuSignOutBtnLabel: 'Confirm',

    saveUserModalCreateUserLabel: 'Create a new user',
    saveUserModalAccountSettingsLabel: 'Account Settings',
    saveUserModalUsernameLabel: 'Username',
    saveUserModalEmailLabel: 'Email',
    saveUserModalAdminLabel: 'Admin',
    saveUserModalRootLabel: 'Root',
    saveUserModalMasterLabel: 'Master',
    saveUserModalMmsManagerLabel: 'MMS Manager',
    saveUserModalValidityTimeLabel: 'Validity time',
    saveUserModalLifetimeLabel: 'Lifetime',
    saveUserModalSetTimeLabel: 'Set a time',
    saveUserModalAllLabel: 'All',
    saveUserModalSelectLicensesLabel: 'Select Licenses',
    saveUserModalAccessedLicensesLabel: 'Accessed Licenses',
    saveUserModalAccessedJobsLabel: 'Accessed Jobs',
    saveUserModalJobsLabel: 'Jobs',
    saveUserModalSelectJobsLabel: 'Select Job',
    saveUserModalAccessToAllChannelsLabel: 'Access to all channels',
    saveUserModalSpecialPrivilegesLabel: 'Special Privileges',
    saveUserModalDownloadDataFileLabel: 'Download data file',
    saveUserModalManageTemplatesLabel: 'Manage templates',

    settingsTablesUsersLabel: 'Users',
    settingsTablesOneJobPasswordsLabel: 'One Job Passwords',
    settingsDbManagementLabel: 'Database Management',
    settingsTemplatesManagementLabel: 'Templates Management',
    settingsDataUsageLabel: 'Data Usage',
    settingsTablesAllowOneJobPasswordLabel: 'Allow one job password',
    settingsTablesNewUserLabel: 'New User',
    settingsTablesNewJobPasswordLabel: 'New Job Password',
    settingsTablesNameHeader: 'Name',
    settingsTablesEmailHeader: 'Email',
    settingsTablesStatusHeader: 'Status',
    settingsTablesCsvExportHeader: 'CSV Export',
    settingsTablesMasterHeader: 'Master',
    settingsTablesRootHeader: 'Root',
    settingsTablesAdminHeader: 'Admin',
    settingsTablesMmsManagerHeader: 'MMS Manager',
    settingsTablesSessionsHeader: 'Sessions',
    settingsTablesCreatedHeader: 'Created',
    settingsTablesJobNameHeader: 'Job Name',
    settingsTablesPasswordHeader: 'Password',
    settingsTablesValidToHeader: 'Valid To',

    dashboardJobsLabel: 'Jobs',
    dashboardStartTimeLabel: 'Start time',
    dashboardStopTimeLabel: 'Stop time',
    dashboardNewItemLabel: 'New Item',
    dashboardOptionsLabel: 'Options',
    dashboardLoadTemplateLabel: 'Load Template',
    dashboardSaveTemplateLabel: 'Save Template',
    dashboardSearchJobLabel: 'Search Job',
    dashboardSearchUnitLabel: 'Search Unit',
    dashboardSearchTemplateLabel: 'Search Template',
    dashboardTemplatesLabel: 'Templates',
    dashboardTemplatesUpdatedAtLabel: 'Updated At',
    dashboardDigitalViewLabel: 'Digital View',
    dashboardChartViewLabel: 'Chart View',
    dashboardEventLoggerLabel: 'Event Logger',
    dashboardJobPropertiesLabel: 'Job Properties',
    dashboardDataTableLabel: 'Data Table',
    dashboardAddNewDigitalDisplayLabel: 'Add digital display',
    dashboardAddNewChartLabel: 'Add chart',
    dashboardAddNewDataTableLabel: 'Add data table',
    dashboardSearchChannelLabel: 'Search Channel',
    dashboardChannelsLabel: 'Channels',
    dashboardOpenInNewWindowLabel: 'Open In New Window',
    dashboardRemoveFromDashboardLabel: 'Remove From Dashboard',
    dashboardManageChannelsLabel: 'Manage Channels',
    dashboardExportToCsvLabel: 'Export To CSV',

    manageAxesModalManageAxesLabel: 'Manage Axes',
    manageAxesModalXAxisLabel: 'X-axis',
    manageAxesModalRangeLabel: 'Range',
    manageAxesModalAllLabel: 'All',
    manageAxesModalLastLabel: 'Last',
    manageAxesModalFormatLabel: 'Format',
    manageAxesModalTimeLabel: 'Time',
    manageAxesModalDateAndTimeLabel: 'Date and Time',
    manageAxesModalSecondsLabel: 'Seconds',
    manageAxesModalModeLabel: 'Mode',
    manageAxesModalLocalLabel: 'Local',
    manageAxesModalDatabaseLabel: 'Database',
    manageAxesModalElapsedLabel: 'Elapsed',
    manageAxesModalYAxisLabel: 'Y-axis',
    manageAxesModalRangeSwitchLabel: 'Range',
    manageAxesModalValueLabel: 'Value',

    manageDateSettingsModalManageDateSettingsLabel: 'Manage Date Settings',
    manageDateSettingsModalLocalLabel: 'Local',
    manageDateSettingsModalDatabaseLabel: 'Database',
    manageDateSettingsModalElapsedLabel: 'Elapsed',
    manageDateSettingsModalTimeFormatLabel: 'Time Format',
    manageDateSettingsModalTimeLabel: 'Time',
    manageDateSettingsModalDateAndTimeLabel: 'Date and Time',
    manageDateSettingsModalSecondsLabel: 'Seconds',
    manageDateSettingsModalDateRangeLabel: 'Date Range',
    manageDateSettingsModalAllLabel: 'All',
    manageDateSettingsModalSelectRangeLabel: 'Select range',
    manageDateSettingsModalStartTimeLabel: 'Start Time',
    manageDateSettingsModalStopTimeLabel: 'Stop Time',

    exportToCsvModalExportToCsvLabel: 'Export To CSV',
    exportToCsvModalFileNameLabel: 'File Name',
    exportToCsvModalDelimiterSymbolLabel: 'Delimiter symbol',
    exportToCsvModaDelimiterSymbolSemicolonLabel: 'Semicolon “ ; ”',
    exportToCsvModalDelimiterSymbolCommaLabel: 'Comma “ , ”',
    exportToCsvModalDelimiterSymbolTabulatorLabel: 'Tabulator',
    exportToCsvModalSpaceLabel: 'Space',
    exportToCsvModalDecimalPointSeparatorLabel: 'Decimal point separator',
    exportToCsvModalPointSeparatorDotLabel: 'Dot “ . ”',
    exportToCsvModalPointSeparatorCommaLabel: 'Comma “ , ”',
    exportToCsvModalDateAndTimeExportFormatLabel: 'Date and time export format',

    siderMyCompanyLabel: 'My Company',
    siderCompanyLabel: 'Company',
    siderSelectDepartmentLabel: 'Select Department',
    siderDashboardLabel: 'Dashboard',
    siderEquipmentLabel: 'Assets',
    siderUnassignedComponentsLabel: 'Unassigned Components',
    siderCatalogLabel: 'Templates',
    siderActivitiesLabel: 'Activities',
    siderScheduledReportsLabel: 'Scheduled Reports',
    siderNotificationsLabel: 'Notifications',

    popoverActiveLabel: 'Active',
    popoverAddComponentLabel: 'Add Component',
    popoverAddSubcomponentLabel: 'Add Subcomponent',
    popoverEditLabel: 'Edit',
    popoverDuplicateLabel: 'Duplicate',
    popoverChangeDepartmentLabel: 'Change department',
    popoverReplaceLabel: 'Replace',
    popoverUninstallLabel: 'Uninstall',
    popoverRemoveLabel: 'Remove',
    popoverTakeToMyEquipmentLabel: 'Take to my equipment',
    popoverShowDetailsLabel: 'Show details',
    popoverAssignPersonLabel: 'Assign person',
    popoverMarkAsCompletedLabel: 'Mark as completed',
    popoverDetailsLabel: 'Details',

    sideMenuEditCompanyLabel: 'Edit Company',
    sideMenuEditLogoLabel: 'Change Logo',
    sideMenuAddNewDepartmentLabel: 'Add Department',
    sideMenuAddNewEmployeeLabel: 'Add Employee',
    sideMenuAddNewComponentLabel: 'Add Asset',
    sideMenuDuplicateLabel: 'Duplicate',
    sideMenuRemoveLabel: 'Remove',
    sideMenuChangeDepartmentLabel: 'Change Department',
    sideMenuTakeToMyEquipmentLabel: 'Take to my equipment',
    sideMenuAddNewPartLabel: 'Add Part',
    sideMenuScheduledReportsLabel: 'Add scheduled report',
    sideMenuCreateNotificationLabel: 'Add notification',
    sideMenuExportReportsLabel: 'Export PDF',
    sideMenuExportSparePartsLabel: 'Export Spare Parts PDF',
    sideMenuExportActivitiesPartsToPdfLabel: 'Export activities parts PDF',
    sideMenuPrintLabel: 'Export PDF',

    myCompanyDepartmentsLabel: 'Departments',
    myCompanyEmployeesLabel: 'Employees',
    myCompanyEmployeeNameHeader: 'Name',
    myCompanyEmployeeDepartmentHeader: 'Department',
    myCompanyEmployeeRoleHeader: 'Role',
    myCompanyEmployeePhoneNumberHeader: 'Phone Number',
    myCompanyEmployeeEmailHeader: 'Email',

    equipmentPageLabel: 'Assets',
    equipmentPageMyEquipmentLabel: 'My Assets',
    equipmentPageRecommendedSparePartsLabel: 'Recommended Spare Parts',
    equipmentPagePartsLabel: 'Parts',
    equipmentPageMyEquipmentNameHeader: 'Description',
    equipmentPageMyEquipmentIdHeader: 'ID',
    equipmentPageMyEquipmentSerialNumberHeader: 'Serial Number',
    equipmentPageMyEquipmentNoteHeader: 'Note',
    equipmentPageMyEquipmentManufacturerHeader: 'Manufacturer',
    equipmentPageMyEquipmentWorkhoursTotalHeader: 'Workhours',
    equipmentPageMyEquipmentActivitiesHeader: 'Activities',
    equipmentPageMyEquipmentActivitiesLabel: 'Activities',
    equipmentPageMyEquipmentStatusHeader: 'Operational Status',
    equipmentPageMyEquipmentStatusLabelActive: 'ACTIVE',
    equipmentPageMyEquipmentStatusLabelInActive: 'INACTIVE',
    equipmentPageActivitiesTypeHeader: 'Type',
    equipmentPageActivitiesDescriptionHeader: 'Description',
    equipmentPageActivitiesPriorityHeader: 'Priority',
    equipmentPageActivitiesTasksHeader: 'Tasks',
    equipmentPageActivitiesDateHeader: 'Expected Date',
    equipmentPageActivitiesWorkhoursHeader: 'Workhours',
    equipmentPageActivitiesAddActivityButton: 'Add activity',
    equipmentPageRecommendedSparePartsSelectComponentsLabel: 'Select assets',
    equipmentPageRecommendedSparePartsSelectedComponentsLabel: 'Selected assets',
    equipmentPageRecommendedSparePartsSelectAllComponentsLabel: 'Selected all assets',
    equipmentPageRecommendedSparePartsPartHeader: 'Part',
    equipmentPageRecommendedSparePartsPartIdHeader: 'Part ID',
    equipmentPageRecommendedSparePartsUidHeader: 'Serial Number',
    equipmentPageRecommendedSparePartsSupplierHeader: 'Supplier',
    equipmentPageRecommendedSparePartsSupplierPartIdHeader: 'Supplier Part Id',
    equipmentPageRecommendedSparePartsManufacturerHeader: 'Manufacturer Details',
    equipmentPageRecommendedSparePartsManufacturerTypeHeader: 'Manufacturer Type',
    equipmentPageRecommendedSparePartsManufacturerPartNumberHeader: 'Manufacturer Part Number',
    equipmentPageRecommendedSparePartsQuantityHeader: 'Quantity',
    equipmentPagePartsPartHeader: 'Part',
    equipmentPagePartsPartIdHeader: 'Part number',
    equipmentPagePartsUidHeader: 'Serial Number',
    equipmentPagePartsSupplierHeader: 'Supplier',
    equipmentPagePartsSupplierPartIdHeader: 'Supplier Part Id',
    equipmentPageManufacturerHeader: 'Manufacturer',
    equipmentPageManufacturerTypeHeader: 'Manufacturer Type',
    equipmentPageManufacturerPartNumberHeader: 'Manufacturer Part Number',

    unassignedComponentsPageLabel: 'Unassigned Components',
    unassignedComponentsTabComponentsLabel: 'Components',
    unassignedComponentsTabActivitiesLabel: 'Activities',
    unassignedComponentsPageNameHeader: 'Name',
    unassignedComponentsPageUidHeader: 'Serial Number',
    unassignedComponentsPageWorkhoursHeader: 'Workhours',
    unassignedComponentsPageLastInstallHeader: 'Last Installed Asset',
    unassignedComponentsPageStateHeader: 'State',
    unassignedComponentsPageActionHeader: 'Action',


    catalogPageLabel: 'Templates',
    catalogPageDropdownComponentsOption: 'Assets',
    catalogPageDropdownPartsOption: 'Parts',

    searchAssetsAndPartsLabel: 'Search',

    activitiesNameHeader: 'Description',
    activitiesPageActivitiesLabel: 'Activities',
    activitiesPageNoActivitiesFoundLabel: 'No activities found.',
    activitiesPageAdjustCriteriaLabel: 'Try to adjust your filter criteria.',
    activitiesPageComponentsLabel: 'Activities',
    activitiesPageComponentHeader: 'Asset',
    activitiesPageTypeHeader: 'Type',
    activitiesPagePriorityHeader: 'Priority',
    activitiesPageTasksHeader: 'Tasks',
    activitiesPageAssignedToHeader: 'Assigned To',
    activitiesPageDateHeader: 'Occurence',
    activitiesPageWorkhoursHeader: 'Workhours',
    activitiesPageDueHeader: 'Due',
    activitiesPageRemainingHeader: 'Remaining',
    activitiesPageCompletedHeader: 'Completed',
    activitiesPageStatusHeader: 'Status',
    activitiesPageStatusHeaderOpened: 'OPENED',
    activitiesPageStatusHeaderCompeted: 'COMPLETED',
    activitiesPageStatusHeaderRemoved: 'REMOVED',
    activitiesPageStatusHeaderOverdue: 'OVERDUE',
    activitiesPageStatusHeaderUpcoming: 'UPCOMING',
    activitiesPagePartsTableLabel: 'Selected activities parts list',
    activitiesPagePartsEmptyListPlaceholderLabel: 'No items found',
    activitiesPagePartsTablePartHeader: 'Part',
    activitiesPagePartsTablePartNumberHeader: 'Part Number',
    activitiesPagePartsTableManufacturerHeader: 'Manufacturer Details',
    activitiesPagePartsTableManufacturerTypeHeader: 'Manufacturer Type',
    activitiesPagePartsTableAlternativePartHeader: 'Alternative Part',
    activitiesPagePartsTableQuantityHeader: 'QUANTITY',
    activitiesPageTasksTableTaskLabel: 'Task',
    activitiesPageTasksTablePartToReplaceLabel: 'Part to replace',
    activitiesPageTasksTablePartReplacedLabel: 'Part replaced',
    activitiesPageTasksTableQuantityLabel: 'Qty',
    activitiesPageTasksTablePartReplacedLabel: 'Part replaced',
    activitiesPageTasksTableDoneLabel: 'Done',
    activitiesPageAlternativePartLabel: 'Alternative part',

    scheduledReportsPageLabel: 'Scheduled Reports',
    scheduledReportsTabLabel: 'Scheduled Reports',
    notificationsPageLabel: 'Notifications',
    notificationsTabLabel: 'Notifications',
    scheduledReportsPageNameHeader: 'Name',
    scheduledReportsPageIntervalHeader: 'Interval',
    scheduledReportsPageRecipientsHeader: 'Recipients',
    scheduledReportsPageStatusHeader: 'Status',
    scheduledReportsPageStatusLabelActive: 'ACTIVE',
    scheduledReportsPageStatusLabelInActive: 'INACTIVE',

    notificationsComponentsQTYHeader: 'Components QTY',
    notificationsAssetsHeader: 'Assets',
    notificationsComponentsHeader: 'Components',
    saveNotificationAddLabel: 'Add Notification',
    saveNotificationEditLabel: 'Notification Details',
    notificationsPageStatusLabelInActive: 'ACTIVE',
    notificationsPageStatusLabelActive: 'INACTIVE',

    saveCompanyModalEditCompanyLabel: 'Edit company',
    saveCompanyModalNameLabel: 'Name',
    saveCompanyModalDescriptionLabel: 'Description',

    saveDepartmentModalAddDepartmentLabel: 'Add Department',
    saveDepartmentModalEditDepartmentLabel: 'Edit Department',
    saveDepartmentModalNameLabel: 'Name',
    saveDepartmentModalNoteLabel: 'Note',
    saveDepartmentModalAssigneeNameLabel: 'Contact person',
    saveDepartmentModalContactPersonLabel: 'Select contact person',
    saveDepartmentModalEmailLabel: 'Email',
    saveDepartmentModalTelephoneLabel: 'Telephone',
    saveDepartmentModalFromDepartmentLabel: 'From Department',
    saveDepartmentModalOthersLabel: 'Others',

    saveEmployeeModalAddEmployeeLabel: 'Add Employee',
    saveEmployeeModalEditEmployeeLabel: 'Edit Employee',
    saveEmployeeModalNameLabel: 'Name',
    saveEmployeeModalRoleLabel: 'Role',
    saveEmployeeModalPhoneNumberLabel: 'Phone Number',
    saveEmployeeModalEmailLabel: 'Email',
    saveEmployeeModalSelectDepartmentLabel: 'Select department',

    saveComponentModalAddComponentLabel: 'Add Asset',
    duplicateComponentModalAddComponentLabel: 'Duplicate Asset',
    saveComponentModalEditComponentLabel: 'Asset Details',
    saveComponentModalAddChildComponentLabel: 'Add Component',
    duplicateComponentModalAddChildComponentLabel: 'Duplicate Component',
    saveComponentModalEditChildComponentLabel: 'Component Details',
    saveComponentModalChooseFromCatalogLabel: 'Choose from Templates',
    saveComponentModalChooseFromUCLabel: 'Choose from Unassigned Components',
    saveComponentModalSelectUCOption: 'Select from unassigned components',
    saveComponentModalSelectFromCatalogOption: 'Select from Templates',
    saveComponentModalCatalogOption: 'Templates',
    saveComponentModalEquipmentOption: 'Assets',
    saveComponentModalComponentLabel: 'Asset',
    saveComponentModalComponentComponentLabel: 'Component',
    saveComponentModalComponentNameLabel: 'Description',
    saveComponentModalComponentPartNumberLabel: 'ID',
    saveComponentModalComponentUidLabel: 'Serial Number',
    saveComponentModalSupplierLabel: 'Supplier',
    saveComponentModalSupplierNameLabel: 'Name',
    saveComponentModalSupplierPartNumberLabel: 'Part Number',
    saveComponentModalSupplierDeliveryTimeLabel: 'Delivery Time [days]',
    saveComponentModalManufacturerLabel: 'Manufacturer',
    saveComponentModalManufacturerNameLabel: 'Manufacturer Name',
    saveComponentModalManufacturerPartNumberLabel: 'Serial Number',
    saveComponentModalManufacturerTypeLabel: 'Type/Model',
    saveComponentModalManufacturerModelLabel: 'Model',
    saveComponentModalWorkhoursLabel: 'Workhours',
    saveComponentModalWorkhoursTotalLabel: 'Workhours Total',
    saveComponentModalWorkhoursOffsetLabel: 'Workhours Offset',
    saveComponentModalWorkhoursRelativeLabel: 'Relative / Total Workhours',
    saveComponentModalNoteLabel: 'Note',
    saveComponentModalSelectUnitLabel: 'Select unit',
    saveComponentModalSelectChannelLabel: 'Select channel',
    saveComponentModalWorkhoursFactorLabel: 'Workhours Factor',
    saveComponentModalDimensionsLabel: 'Dimensions',
    saveComponentModalLengthLabel: 'Length',
    saveComponentModalWidthLabel: 'Width',
    saveComponentModalHeightLabel: 'Height',
    saveComponentModalWeightLabel: 'Weight',
    saveComponentModalWeightPlaceholder: 'Weight',
    saveComponentModalRecommendedSparePartsLabel: 'Recommended Spare Parts',
    saveComponentModalAddButtonLabel: 'Add',
    saveComponentModalUserManualsLabel: 'User Manuals, Certificates, Files',
    saveComponentModalUploadButtonLabel: 'Upload',
    saveComponentSyncCloudLatestJobLabel: 'Latest job: ',
    saveComponentSyncCloudLatestJobLabelStartTime: 'Start time: ',
    saveComponentSyncCloudLatestJobLabelLicense: 'License: ',
    recommendedSparePartsEmptyListPlaceholderLabel: 'No items found',
    changeComponentStateModalLabel: 'Change component state',

    cloneComponentModalPermissionLabel: 'Permission',
    cloneComponentModalMessageLabel: 'Do you want to clone the asset?',
    cloneComponentsToEquipmentModalMessageLabel: `Do you want to clone the assets to your equipment?`,
    clonePartsToEquipmentModalMessageLabel: `Do you want to clone the parts to your equipment?`,

    removeComponentModalPermissionLabel: 'Permission',
    removeComponentModalMessageLabel: 'Do you want to remove the asset?',
    removeSubComponentModalMessageLabel: 'Do you want to remove the component?',
    removeComponentsModalMessageLabel: 'Do you want to remove the assets?',
    removePartModalMessageLabel: 'Do you want to remove the part?',
    removePartsModalMessageLabel: 'Do you want to remove the parts?',
    removeReportModalMessageLabel: 'Do you want to remove the report?',
    removeReportsModalMessageLabel: 'Do you want to remove the reports?',
    removeUserModalMessageLabel: 'Do you want to remove the user?',
    removeJobModalMessageLabel: 'Do you want to remove "{name}" job?',
    removeSettingsTemplateModalMessageLabel: 'Do you want to remove "{name}" template?',
    removeOneJobPasswordModalMessageLabel: 'Do you want to remove the one job password?',
    removeTemplateModalMessageLabel: 'Do you want to remove the template?',
    removeDepartmentModalMessageLabel: 'Do you want to remove the department?',
    removeEmployeeModalMessageLabel: 'Do you want to remove the employee?',
    removeTaskModalMessageLabel: 'Do you want to remove the task?',
    removeActivityModalMessageLabel: 'Do you want to remove the activity?',

    changeDepartmentModalLabel: 'Change Department',

    savePartModalAddPartLabel: 'Add Part',
    savePartModalEditPartLabel: 'Part Details',
    savePartModalChooseFromCatalogLabel: 'Choose from Templates',
    savePartModalSelectFromCatalogOption: 'Select from Templates',
    savePartModalPartLabel: 'Part',
    savePartModalPartNameLabel: 'Name',
    savePartModalPartNumberLabel: 'Part Number',
    savePartModalPartUidLabel: 'Serial Number',
    savePartModalSupplierLabel: 'Supplier',
    savePartModalSupplierNameLabel: 'Name',
    savePartModalSupplierPartNumberLabel: 'Part Number',
    savePartModalSupplierDeliveryTimeLabel: 'Delivery Time [days]',
    savePartModalManufacturerLabel: 'Manufacturer',
    savePartModalManufacturerNameLabel: 'Name',
    savePartModalManufacturerPartNumberLabel: 'Part Number',
    savePartModalManufacturerTypeLabel: 'Type/Model',
    savePartModalDimensionsLabel: 'Dimensions',
    savePartModalLengthLabel: 'Length',
    savePartModalWidthLabel: 'Width',
    savePartModalHeightLabel: 'Height',
    savePartModalWeightLabel: 'Weight',
    savePartModalWeightPlaceholder: 'Weight',
    savePartModalUserManualsLabel: 'User Manuals, Certificates, Files',
    savePartModalUploadButtonLabel: 'Upload',

    takeComponentsModalPermissionLabel: 'Permission',
    takeComponentsModalMessageLabel: 'Do you want to clone the assets to your equipment?',

    replaceComponentModalLabel: 'Replace Component',
    replaceComponentModalComponentFieldLabel: 'Select component',
    replaceComponentModalStateFieldLabel: 'Select state',
    replaceComponentModalComponentWorkhoursLabel: `Component's workhours`,
    replaceComponentModalDateLabel: `Date`,

    uninstallComponentModalLabel: 'Uninstall Component',
    uninstallComponentModalUninstalledAtLabel: 'Uninstalled At',
    uninstallComponentModalUninstalledOnLabel: 'Uninstalled On',
    uninstallComponentModalMoveToLabel: 'Move uninstalled component to:',

    
    saveActivityModalAddActivityLabel: 'Add Activity',
    saveActivityModalSelectComponentOptionLabel: 'Choose from existing activities',
    saveActivityModalSelectComponentOption: 'Select Component',
    saveActivityModalSelectActivityOption: 'Select Activity',
    saveActivityModalEditActivityLabel: 'Activity Details',
    saveActivityModalActivityLabel: 'Activity',
    saveActivityModalNameLabel: 'Description',
    saveActivityModalTypeLabel: 'Type',
    saveActivityModalOccurrenceLabel: 'Occurrence',
    saveActivityModalPriorityLabel: 'Priority',
    saveActivityModalActionTriggersLabel: 'Action Triggers',
    saveActivityModalAddAsCompleted: 'Completed',
    saveActivityModalOnDemand: 'On Demand',
    saveActivityModalTimeLabel: 'Time',
    saveActivityModalOccurrenceIntervalLabel: 'Recurrence interval',
    saveActivityModalDateLabel: 'Date',
    saveActivityModalNotificationsLabel: 'Notifications',
    saveActivityModalWorkhoursLabel: 'Workhours',
    saveActivityModalTimeIntervalLabel: 'Time interval',
    saveActivityModalDateIntervalLabel: 'Interval',
    saveActivityModalNextOccurrenceLabel: 'Next occurrence',
    saveActivityModalWorkhoursIntervalLabel: 'Workhours interval',
    saveActivityModalCompleteWorkhoursLabel: 'Workhours',
    saveActivityModalNextOccurenceLabel: 'Next occurence',

    saveActivityOccurrenceTab: 'Occurrence',
    saveActivityNotificationSettingsTab: 'Notification Settings',
    notificationsDateTimeEnabled: 'Time',
    notificationsNextDateTime: 'Next Notification Datetime',
    notificationsDateTimePeriod: 'Number of',
    notificationsDateRange: 'Offset',
    notificationsWorkhoursEnabled: 'Workhours',
    notificationsWorkhoursOffset: 'Number of workhours',
    notificationsNextWorkhours: 'Next Notification Workhours',
    removeNotificationModalMessageLabel: 'Do you want to remove "{name}" notification?',
    removeNotificationsModalMessageLabel: 'Do you want to remove selected notifications?',

    saveActivityModalTasksLabel: 'Tasks',
    saveActivityModalAddButtonLabel: 'Add',
    saveActivityModalTaskLabel: 'Task',
    saveActivityModalPartToReplaceLabel: 'Part to replace',
    saveActivityModalPartReplacedLabel: 'Part replaced',
    saveActivityModalQuantityLabel: 'Qty',
    saveActivityModalAlternativePartLabel: 'Alternative part',
    saveActivityModalSelectAlternativePartLabel: 'Select Alternative part',
    saveActivityModalEstimatedWorkTimeLabel: 'Estimated work time',
    saveActivityModalAmountLabel: 'Amount',
    saveActivityModalSkillsLabel: 'Skills',
    saveActivityModalToolsLabel: 'Tools',
    saveActivityModalCommentsLabel: 'Comments',
    saveActivityModalFilesLabel: 'Files',
    saveActivityModalUploadButtonLabel: 'Upload',

    filterActivitiesModalLabel: 'Activities',
    filterActivitiesModalStatusLabel: 'Status',
    filterActivitiesModalSelectStatusLabel: 'Select status',
    filterActivitiesModalStatesLabel: 'States',
    filterActivitiesModalSelectStatesLabel: 'Select state',
    filterActivitiesModalDateRangeLabel: 'Date range',
    filterActivitiesModalSelectDateRangeLabel: 'Select date range',
    filterActivitiesModalComponentLabel: 'Asset',
    filterActivitiesModalSelectComponentLabel: 'Select asset',
    filterActivitiesModalPriorityLabel: 'Priority',
    filterActivitiesModalSelectPriorityLabel: 'Select priority',
    filterActivitiesModalAssigneeLabel: 'Assignee',
    filterActivitiesModalSelectAssigneeLabel: 'Select assignee',
    filterActivitiesModalNoAssigneeLabel: 'No assignee',
    filterActivitiesModalSelectPartLabel: 'Select part',

    assignPersonModalLabel: 'Assign Person',
    assignPersonModalSelectAssigneeLabel: 'Select assignee',

    markAsCompletedModalLabel: 'Mark as completed',
    markAsCompletedModalEmployeeLabel: 'Employee',
    markAsCompletedModalSelectAssigneeLabel: 'Select assignee',
    markAsCompletedModalSelectPartLabel: 'Select part',
    markAsCompletedModalDateLabel: 'Completed Date',
    markAsCompletedModalAmountLabel: 'Completed Workhours',
    markAsCompletedModalIntervalLabel: 'Labor Time',
    markAsCompletedModalTaskHeader: 'Task',
    markAsCompletedModalNoTasksWereAdded: 'No tasks were added',
    markAsCompletedModalTaskInstructionPopoverTaskInstructionLabel: 'Task instruction',
    markAsCompletedModalTaskInstructionPopoverPartToReplaceLabel: 'Part to replace:',
    markAsCompletedModalTaskInstructionPopoverQtyLabel: 'QTY:',
    markAsCompletedModalTaskInstructionPopoverAlternativePart: 'Alternative part:',
    markAsCompletedModalPartReplacedHeader: 'Part replaced',
    markAsCompletedModalQtyHeader: 'Qty',
    markAsCompletedModalDoneHeader: 'Done',
    markAsCompletedModalCommentsLabel: 'Comments',
    markAsCompletedModalFilesLabel: 'Files',
    markAsCompletedModalUploadButtonLabel: 'Upload',

    saveReportModalAddReportLabel: 'Add Report',
    saveReportModalEditReportLabel: 'Report Details',
    saveReportModalReportSpecificationLabel: 'Report specification',
    saveReportModalNameLabel: 'Name',
    saveReportModalFrequencyLabel: 'Frequency',
    saveReportModalSelectFrequencyLabel: 'Select frequency',
    saveReportModalTimeLabel: 'Time:',
    saveReportModalStartDateLabel: 'Start date',
    saveReportModalRecipientsLabel: 'Recipients',
    saveReportModalAddButtonLabel: 'Add',
    saveReportModalAppliedFiltersLabel: 'Applied Filters',
    saveReportModalStatusLabel: 'Status',
    saveReportModalStatesLabel: 'States',
    saveReportModalSelectStatusLabel: 'Select status',
    saveReportModalDateRangeLabel: 'Date range',
    saveReportModalSelectDateRangeLabel: 'Select date range',
    saveReportModalComponentLabel: 'Asset',
    saveReportModalSelectComponentLabel: 'Select asset',
    saveReportModalPriorityLabel: 'Priority',
    saveReportModalSelectPriorityLabel: 'Select priority',
    saveReportModalAssigneeLabel: 'Assignee',
    saveReportModalSelectAssigneeLabel: 'Select assignee',
    saveReportModalNoAssigneeLabel: 'No assignee',

    notificationsInvavidDates: "'Start Time' must be earlier than 'Stop Time'",
    notificationsExportError: 'Error during export occured',
    notificationsComponentsDeleted: 'Components successfully removed!',
    notificationsComponentDeleted: 'Component successfully removed!',
    notificationsPartsDeleted: 'Parts successfully deleted!',
    notificationsPartDeleted: 'Part successfully deleted!',
    notificationsPartAdded: 'Part successfully added!',
    notificationsPartUpdated: 'Part successfully updated!',
    notificationsPartsCloned: 'Parts successfully cloned!',
    notificationsPartCloned: 'Part successfully cloned!',
    notificationsReportsDeleted: 'Reports successfully deleted!',
    notificationsReportDeleted: 'Report successfully deleted!',
    notificationsReportAdded: 'Report successfully added!',
    notificationsReportUpdated: 'Report successfully updated!',
    notificationsFileNameRequired: 'File name is required',
    notificationsLoadTemolateError: 'Template can not be loaded. User has no permissions to view template channels',
    notificationsEmployeeAssigned: 'Employee successfully assigned!',
    notificationsActivityCompleted: 'Activity successfully completed!',
    notificationsActivityUpdated: 'Activity successfully updated!',
    notificationsActivityAdded: 'Activity successfully added!',
    notificationsActivityCompletedAdded: 'Activity successfully added and completed!',
    notificationsComponentsCloned: 'Assets successfully cloned!',
    notificationsComponentCloned: 'Asset successfully cloned!',
    notificationsAssetAdded: 'Asset successfully added!',
    notificationsAssetDeleted: 'Asset successfully removed!',
    notificationsAssetUpdated: 'Asset successfully updated!',
    notificationsComponentAdded: 'Component successfully added!',
    notificationsComponentSerialNumberAdded: 'Сomponent with this serial number already exists!',
    notificationsTemplateAdded: 'Template successfully added!',
    notificationsComponentUpdated: 'Component successfully updated!',
    notificationsCompanyUpdated: 'Company details successfully updated!',
    notificationsDepartmentUpdated: `Department successfully updated!`,
    notificationsDepartmentAdded: `Department successfully added!`,
    notificationsDepartmentDeleted: 'Department successfully deleted!',
    notificationsEmployeeUpdated: `Employee successfully updated!`,
    notificationsWorkhoursUpdated: `Workhours successfully updated!`,
    notificationsEmployeeAdded: `Employee successfully added!`,
    notificationsEmployeeDeleted: `Employee successfully deleted!`,
    notificationsTaskDeleted: `Task successfully deleted!`,
    notificationsActivityDeleted: `Activity successfully deleted!`,
    notificationsComponentDepartmentChanged: `Asset's department successfully changed!`,
    notificationsEmailResent: 'Email successfully resent!',
    notificationsLinkCopied: 'Link copied!',

    removeUCModalMessageLabel: 'Do you want to remove the unassigned components?',

    emailConfirmationPageLabel: 'Email Confirmation',

    passwordResetPageLabel: 'Create a new password',

    setPasswordPageLabel: 'Set password',

    changePasswordPageLabel: 'Change password',
    changePasswordPageOldPasswordLabel: 'Old password',
    changePasswordPagePasswordLabel: 'Password',
    changePasswordPageConfirmPasswordLabel: 'Confirm password',
    changePasswordPageBackLabel: 'Back',

    signInPageEmailSwitchLabel: 'Email',
    signInPageEmailLabel: 'Email',
    signInPageOneJobPasswordLabel: 'One Job Password',
    signInPagePasswordLabel: 'Password',
    signInPageForgotLabel: 'Forgot?',

    forgotPasswordPageLabel: 'Forgot your password?',
    forgotPasswordPageEnterEmailLabel: 'Please enter your email address',
    forgotPasswordPageEmailAddressLabel: 'Email Address',
    forgotPasswordPageSignInLabel: 'Sign In',
    forgotPasswordPagePasswordLabel: 'Password',
    forgotPasswordPageConfirmPasswordLabel: 'Confirm Password',
    forgotPasswordPageRequestSentLabel: 'Forgot password request has been successfully sent. Check your email for a link to reset password',

    signUpLabel: 'Sign Up',
    signUpPageAccountRegisteredLabel: 'Your Dms Suite account has been successfully registered. Check your email for a link to complete registration',
    signUpPageLicenseIdLabel: 'License ID',
    signUpPageEmailLabel: 'Email',
    signUpPagePasswordLabel: 'Password',
    signUpPageConfirmPasswordLabel: 'Confirm Password',
    signUpPageHaveAnAccountLabel: 'Have an account?',
    signUpPageLoginLabel: 'Login',

    dbSizeInfo: '{used} of {limit}GB',
    tableColumnName: 'Name',
    tableColumnStartTime: 'Start Time',
    tableColumnStopTime: 'Stop Time',
    tableColumnUpdatedAtTime: 'Updated At',
    tableColumnSize: 'Size, Mb',
    tableColumnJobType: 'Type',

    deleteJobMessage: 'Job has been deleted',
    deleteTemplateMessage: 'Template has been deleted',
    autoDeleteJobMessage: 'Automatic job removal has been activated. Job data will be automatically cleared out daily in the background',

    mmsAccessError: 'MMS license module is not found or expired. Please, contact system administrator',

    selectJobLabel: 'Select Job',
    selectDateRangeLabel: 'Select Date Range',
    selectFilterType: 'Select Filter',
    jobLabel: 'Job',
    dateRangeLabel: 'Date Range',

    clearAllFiltersLabel: 'Clear all filters',
    showFiltersLabel: 'Show filters',
    hideFiltersLabel: 'Hide filters',

    emptyDataTitle: 'No data found.',
    emptyDataSubtitle: 'Try to adjust your search criteria.',
    valueLabel: 'Value',
    fromLabel: 'From',
    toLabel: 'To',
    yesLabel: 'Yes',
    nowLabel: 'No',
    confirmChangeJobQuestion: 'Are you sure you want to change job?',
    confirmChangeUnitQuestion: 'Are you sure you want to change unit?',

    exportBuildingStartedMessage: 'Export has been successfully started',
    downloadBtnLabel: 'Download',
    buildingCsvLabel: 'Building CSV',

    confirmChangeRootRoleQuestion: 'Existing Root user will be overwritten. Are you sure you want to proceed?',

    dataUsageCompanyNameLabel: 'Company Name',
    dataUsageTotalImportsLabel: 'Total Imports',
    dataUsageTotalLocationsLabel: 'Total Locations',
    dataUsageTotalSizeLabel: 'Total Size (MB)',
    dataUsageTotalUnitsLabel: 'Total Units',
    dataUsageTotalJobsLabel: 'Total Jobs',

    selectComponentLabel: 'All components',

    timezoneLabel: 'Timezone'
}